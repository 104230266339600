<template>
  <el-dialog
    :title="title"
    :visible.sync="setShow"
    @close="closeEvent"
    width="30%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item label="" prop="type" :rules="validateField()">
            <el-radio-group v-model="form.type" class="eden-switch-radio small">
              <el-radio-button label="specific">Specific day</el-radio-button>
              <el-radio-button label="multiple">Multiple days</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item label="Service" prop="service" :rules="validateField()">
            <el-select v-model="form.service" placeholder="Select service">
              <el-option label="All" value="all" />
              <el-option
                v-for="(service, i) in services"
                :key="i"
                :label="`${service.service_name} ${
                  service.slug === 'laundry' ? '(Pickup date)' : ''
                }`"
                :value="service.slug"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <template v-if="form.type === 'specific'">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Specific date"
              prop="start_date"
              :rules="validateField()"
            >
              <el-date-picker
                v-model="form.start_date"
                type="date"
                value-format="yyyy-MM-dd"
                format="dd MMM, yyyy"
                placeholder="Select date"
                :picker-options="{
                  disabledDate: disabledDates,
                }"
                prefix-icon="eden-icon-calendar"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <template v-else>
        <el-row type="flex" :gutter="15" class="is-flex-wrap">
          <el-col :md="12">
            <el-form-item
              label="Start date"
              prop="start_date"
              :rules="validateField()"
            >
              <el-date-picker
                v-model="form.start_date"
                type="date"
                placeholder="From"
                value-format="yyyy-MM-dd"
                format="dd MMM, yyyy"
                :picker-options="{
                  disabledDate: disabledDates,
                }"
                prefix-icon="eden-icon--calendar"
              />
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item
              label="End date"
              prop="end_date"
              :rules="validateField()"
            >
              <el-date-picker
                v-model="form.end_date"
                type="date"
                placeholder="To"
                value-format="yyyy-MM-dd"
                format="dd MMM, yyyy"
                :picker-options="{
                  disabledDate: disableStartDate,
                }"
                prefix-icon="eden-icon--calendar"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </template>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button type="primary" :loading="changing" @click="change">{{
        title
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import orders from "@/requests/orders";

export default {
  name: "OrdersStatusChange",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        type: "specific",
        service: "",
        start_date: "",
        end_date: "",
      },
      changing: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    services() {
      return this.$store.getters.services;
    },
    title() {
      return this.action === "pause" ? "Pause Orders" : "Unpause Orders";
    },
  },
  methods: {
    disabledDates(time) {
      if (this.allowAccessFor(["superadmin"])) {
        return false;
      }
      return time.getTime() < new Date().getTime();
    },
    disableStartDate(time) {
      return time.getTime() <= new Date(this.form.start_date).getTime();
    },
    closeEvent() {
      this.changing = false;
      this.setShow = false;
      this.$refs.form.resetFields();
    },
    change() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.changing = true;
        let payload = {
          service: this.form.service,
          start_date: this.form.start_date,
          end_date: this.form.end_date,
        };

        if (this.form.type === "specific") {
          payload.end_date = this.form.start_date;
        }

        orders
          .status(this.action, payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.$emit("success");
              this.closeEvent();
            }
          })
          .catch((error) => {
            this.$message.error(error.response.data.message);
            this.closeEvent();
          });
        return true;
      });
    },
  },
};
</script>
